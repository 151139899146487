<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCreate="onCreate"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            :label="'Customers List'"
            :icon="value.icon"
            :actions="actions"
            @onCreate="onCreate"
            underline
            right
          ></FormSection>
          <v-list-item-group color="primary">
            <v-list-item
              @click="onSelect(customer)"
              v-for="customer in customers"
              :key="customer.id"
              :input-value="customer.id === activeCustomerId"
            >
              <v-list-item-avatar>
                <v-img
                  :style="{
                    background: customer.user.profile.color,
                  }"
                  :src="customer.user.profile.avatarURL"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-html="
                    customer.user.profile.f_name +
                    ' ' +
                    customer.user.profile.l_name
                  "
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="customer.user.email"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <!-- <v-pagination
            class="pag"
            color="primary"
            v-model="page"
            :length="15"
            :total-visible="7"
          ></v-pagination> -->
        </v-col>
      </v-row>
    </template>
  </AView>
</template>
  
  
  
<script>
import { mapState } from "vuex";
import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import { GraphUI } from "../../../GrapUI.config.js";
import { CustomerCreation } from "../app.gh.js";

export default {
  name: "UsersList",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
  },
  computed: mapState("CustomerStore", ["activeCustomerId", "customers"]),
  created() {
    this.$store.dispatch("CustomerStore/GetCustomersList");
  },
  data() {
    return {
      page: 1,
      scene: GraphUI.scene,
      actions: [
        {
          name: "Create",
          description: "Will create new Customer",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
        {
          name: "Close",
          description: "Will close this window",
          icon: "mdi-trash-can-outline",
          color: "#0e1721",
          on: "onClose",
        },
      ],
    };
  },
  methods: {
    async onCreate() {
      await this.scene.add(this.value, CustomerCreation, {});
      // RView.focus();
    },
    onSelect(customer) {
      this.$store.dispatch("CustomerStore/SetActiveCustomer", {
        from: this.value,
        customer,
      });
    },
  },
  watch: {
    activeCustomerId(newVal) {
      if (newVal) {
        const index = this.customers.findIndex((el) => el.id === newVal);
        this.active = index;
      }
    },
  },
};
</script>
  
  
  
  <style scoped lang="scss" >
.users-list {
  height: 100%;
  width: 100%;
  // ::v-deep button {
  //   background: white !important;
  // }
}
</style>