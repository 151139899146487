var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AView',{attrs:{"value":_vm.value,"actions":_vm.actions},on:{"onActivate":(e) => _vm.$emit('onActivate', e),"onMaximize":(e) => _vm.$emit('onMaximize', e),"onClose":(e) => _vm.$emit('onClose', e),"onCreate":_vm.onCreate},scopedSlots:_vm._u([{key:"content",fn:function({}){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('FormSection',{attrs:{"label":'Customers List',"icon":_vm.value.icon,"actions":_vm.actions,"underline":"","right":""},on:{"onCreate":_vm.onCreate}}),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.customers),function(customer){return _c('v-list-item',{key:customer.id,attrs:{"input-value":customer.id === _vm.activeCustomerId},on:{"click":function($event){return _vm.onSelect(customer)}}},[_c('v-list-item-avatar',[_c('v-img',{style:({
                  background: customer.user.profile.color,
                }),attrs:{"src":customer.user.profile.avatarURL}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                  customer.user.profile.f_name +
                  ' ' +
                  customer.user.profile.l_name
                )}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(customer.user.email)}})],1)],1)}),1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }